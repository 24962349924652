import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Header from '../components/Header';
import AboutContent from '../components/About/AboutContent';

export const AboutPageTemplate = ({
  title,
  subtitle,
  challenge,
  achieving,
  cta
}) => {
  return (
    <div className="w-full flex flex-col bg-s4tk-white">
      <Header title={title} />
      <AboutContent
        subtitle={subtitle}
        challenge={challenge}
        achieving={achieving}
        cta={cta}
      />
    </div>
  );
};

const AboutPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <AboutPageTemplate
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        challenge={frontmatter.challenge}
        achieving={frontmatter.achieving}
        cta={frontmatter.cta}
      />
    </Layout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        title
        subtitle
        challenge {
          title
          subtitle
          description
        }
        achieving {
          title
          subtitle
          items {
            text
          }
        }
        cta {
          blurb
          item {
            title
            description
            icon {
              prefix
              title
            }
            url
          }
        }
      }
    }
  }
`;
