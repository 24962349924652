import React from 'react';
import Section, { SubSection } from '../Section';
import SmallLink from '../SmallLink';

export const AboutContent = ({ subtitle, challenge, achieving, cta }) => {
  return (
    <Section>
      <p className="font-baloo text-s4tk-blue text-3xl text-left sm:text-justify px-0 md:px-4 lg:px-8">
        {subtitle}
      </p>
      <SubSection>
        <p className="font-baloo text-s4tk-blue text-3xl font-bold mb-2">
          {achieving.title}
        </p>
        <p className="font-baloo text-s4tk-blue text-xl mb-4">
          {achieving.subtitle}
        </p>
        <ul className="list-disc list-inside px-0 md:px-4 lg:px-8 space-y-2">
          {achieving.items.map((item, index) => (
            <li
              key={index}
              className="font-baloo text-black text-xl text-left sm:text-justify"
            >
              {item.text}
            </li>
          ))}
        </ul>
      </SubSection>
      <SubSection>
        <p className="font-baloo text-s4tk-blue text-3xl font-bold mb-2">
          {challenge.title}
        </p>
        <p className="font-baloo text-s4tk-blue text-xl mb-4">
          {challenge.subtitle}
        </p>
        <p className="font-baloo text-black text-xl text-left sm:text-justify self-center px-0 md:px-4 lg:px-8">
          {challenge.description}
        </p>
        <div className="w-full flex flex-col justify-center items-center py-16">
          <p className="font-baloo text-s4tk-blue text-2xl sm:text-3xl text-left sm:text-justify px-0 md:px-4 lg:px-8 mb-16">
            {cta.blurb}
          </p>
          <SmallLink internal item={cta.item} />
        </div>
      </SubSection>
    </Section>
  );
};

export default AboutContent;
